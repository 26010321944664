//
// Mixins
// Used in conjunction with global variables to enable certain theme features.
// stylelint-disable declaration-no-important
// Only display content to screen readers
//
// See: https://a11yproject.com/posts/how-to-hide-content/
// See: https://hugogiraudel.com/2016/10/13/css-hide-and-seek/
@mixin sr-only {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important; // Fix for https://github.com/twbs/bootstrap/issues/25686
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

// Use to only display content when it's focused.
//
// Useful for "Skip to main content" links; see https://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
@mixin sr-only-focusable {
  &:not(:focus) {
    @include sr-only();
  }
}

@import "white_frame";
@mixin overflow-scroll() {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

@mixin custom-range($name, $color) {
  .custom-range-#{$name} {
    &::-webkit-slider-runnable-track {
      background-color: $color;
    }

    &::-moz-range-track {
      background-color: $color;
    }

    &::-ms-fill-lower {
      background-color: $color;
    }

    &::-ms-fill-upper {
      background-color: $color;
    }
  }
}

@mixin custom-range-thumb($name, $color) {
  .custom-range-thumb-#{$name} {
    &::-webkit-slider-thumb {
      @include gradient-bg($color);

      &:active {
        @include gradient-bg(lighten($color, 25%));
      }
    }

    &::-moz-range-thumb {
      @include gradient-bg($color);

      &:active {
        @include gradient-bg(lighten($color, 25%));
      }
    }

    &::-ms-thumb {
      @include gradient-bg($color);

      &:active {
        @include gradient-bg(lighten($color, 25%));
      }
    }
  }
}

@mixin flexScrollFix() {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 auto;
}

@mixin absolute-centered($font-size) {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -($font-size / 2);
  margin-left: -($font-size / 2);
  font-size: $font-size;
}

@mixin absolute-centered-y($font-size) {
  position: absolute;
  top: 50%;
  margin-top: -($font-size/2);
  font-size: $font-size;
}

@mixin not-selectable($value: none) {
  cursor: default;
  user-select: $value;
  -webkit-touch-callout: $value;
}

@mixin object-fit($fit: fill, $position: null) {
  object-fit: $fit;
  @if $position {
    object-position: $position;
    font-family: "object-fit: #{$fit}; object-position: #{$position}";
  } @else {
    font-family: "object-fit: #{$fit}";
  }
}

@mixin button-flat-variant($color, $color-hover: $default-hover-bg, $has-bg: "false") {
  color: $color;
  //background-image: none;
  border-color: transparent;
  @if $has-bg == "false" {
    background-color: transparent;
  } @else {
    background-color: $default-hover-bg;
  }
  @include hover {
    background-color: $color-hover;
    @if $has-bg == "false" {
      color: $color;
    } @else {
      color: darken($color, 10%);
    }
    border-color: transparent;
  }
  &:focus,
  &.focus {
    outline: 0;
    //box-shadow: none;
  }
  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent !important;
  }
  &.btn-selected,
  &:active,
  &.active,
  .show > &.dropdown-toggle {
    color: $color;
    background-color: $color-hover;
    border-color: transparent;
  }
}

@mixin drag-drop-highlight {
  box-shadow: inset 0 0 0 1px $blue;
  cursor: default;
}

// Overflows
@mixin pretty-scroll-mixin($show-on-hover: true, $overflow-auto: true) {
  @if $overflow-auto == true {
    overflow: auto;
  }
  -webkit-overflow-scrolling: touch;
  //scroll-behavior: smooth;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    @include size($spacer / 2);
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    @if ($show-on-hover != false) {
      background-color: transparent;
    } @else {
      background-color: rgba($black, 0.5);
    }
  }
  &:hover,
  &:focus {
    &::-webkit-scrollbar-thumb {
      background-color: rgba($black, 0.5);
    }
  }
}

@mixin mb-scroll-mixin($show-on-hover: true, $overflow-auto: null) {
  @if ($overflow-auto != false) {
    overflow: auto;
  }
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  //overscroll-behavior: contain;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    @include size($spacer / 2);
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    @if ($show-on-hover != false) {
      background-color: transparent;
    } @else {
      background-color: rgba($black, 0.5);
    }
  }
  &:hover,
  &:focus {
    &::-webkit-scrollbar-thumb {
      background-color: rgba($black, 0.5);
    }
  }
}

// Sizing shortcuts
@mixin min-size($width, $height: $width) {
  min-width: $width;
  min-height: $height;
}

@mixin max-size($width, $height: $width) {
  max-width: $width;
  max-height: $height;
}

@mixin square($value) {
  width: $value;
  height: $value;
}
