//
// Icons
// https://material.io/tools/icons/
//
@font-face {
  font-family: $font-family-material;
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: local($font-family-material), local("MaterialIcons-Outlined"),
    url("fonts/MaterialIcons-Outlined.woff2") format("woff2");
}

.DefaultHeader::before,
.LoadingImage::before,
.MBButton::after,
.MBButton::before,
.MIFont::before,
.MediaPlayButton::before,
.TextBoxItem::after,
.arrow_priority::before,
.btn-chip:not(.btn-chip-color)::after,
.dashboardTitle::before,
.defaultUserIcon::before,
.dnd-handle::before,
.dropAdd::before,
.dropdown-item-no-index-true::after,
.dropdown-item.nonIndexed::after,
.filterField .x-form-trigger::before,
.folder::before,
.gwt-RadioButton label::before,
.hasChildren::before,
.highlighter .x-grid3-row-table .x-grid-cell-first::before,
.isActive::before,
.loading-indicator::before,
.mb-checkbox::before,
.mb-header-grid .x-grid3-hd-inner::after,
.mb-header-grid .x-grid3-hd-inner::before,
.mb-radio label::before,
.photo-wall .alert-component-error::before,
.processingImage::before,
.sort-asc .x-grid3-hd-inner::before,
.sort-desc .x-grid3-hd-inner::before,
.star::before,
.x-date-mp-next::after,
.x-date-mp-prev::after,
.x-grid-group-hd::before,
.admin-mode .x-grid3-hd-__plus__,
.x-grid3-row-checker::before,
.x-icon-btn::after,
.x-menu-item-arrow::after,
.x-tool-close::before,
.x-tool-gear::before,
.x-tool::before,
  //.has-highlighter-icon-before::before,
  //.R3Search-Filter-Field-base:before,
.has-icon-before::before,
.has-icon-after::after,
.material-icon {
  @include material_icon_base();

  .ui-dark-mode & {
    &:not(.text-primary) {
      color: currentColor !important;
    }
  }
}

.has-icon-before,
.has-icon-after {
  &.text-white {
    &::before,
    &::after {
      color: $white;
    }
  }
}

.material-svg-icon {
  fill: $md-dark-icons-default;
}

.icon-default-style {
  transform: scale(1.3);

  .btn:hover &,
  .btn:focus & {
    color: currentColor !important;
  }
}

.icon-font-scale {
  transform: scale(1.3);
}

.icon-font-size {
  font-size: $icon_font_size;
}

.icon-size {
  width: $icon_font_size;
  height: $icon_font_size;
}

.icon-font-size-lg {
  font-size: $default-icon-font-size-lg;
}

.icon-font-size-xl {
  font-size: $font-size-base;
}

.icon-rotate-90-minus {
  transform: rotate(-90deg);
}

.icon-rotate-90 {
  transform: rotate(90deg);
}

.resetButton {
  .material-icon {
    transform: rotate(45deg);
  }
}

.has-icon-before::before,
.has-icon-after::after,
.icon-transform-default,
.icon-will-transform {
  transition: transform 0.27s $mat-fast-out-slow-in-timing-function;
}

.icon-scale {
  transform: scale(1.5);
}

.icon-rotate-180,
.highlighter .x-grid3-row-table .x-grid-cell-first::before,
.has-highlighter-icon-before::before,
.is-open > .card-header > .icon-will-transform {
  transform: rotate(180deg);
}

.icon-rotate-180-target-active {
  &.active {
    .material-icon {
      transform: rotate(180deg);
    }
  }
}

.icon-rotate-180-is-open {
  .active &,
  .is-open & {
    transform: rotate(180deg);
  }
}

.icon-rotate-270 {
  transform: rotate(270deg);
}

.icon-flip-horizontal {
  transform: scale(-1, 1);
}

.icon-flip-vertical {
  transform: scale(1, -1);
}

.LoadingImage,
.waiting-background,
.running .dashboardTitle {
  &::before {
    content: "autorenew";
  }
}

.LoadingImage,
.waiting-background,
.running .dashboardTitle,
.icon-spin {
  &::before {
    animation: spin 1.25s infinite linear;
  }
}

.svg-check-primary,
.svg-check-success {
  position: absolute;
  opacity: 1;
  fill-opacity: 1;
}

.svg-check-primary {
  fill: theme-color("primary");
}

.svg-check-success {
  fill: theme-color("success");
}

.svg-check-white-bg {
  position: absolute;
}

.x-grid-group-hd {
  &::before {
    @extend .icon-font-size;
    @extend .icon_arrow_drop_down;
  }

  .x-grid-group-collapsed & {
    &::before {
      transform: rotate(-90deg);
    }
  }
}

.loading-indicator {
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-direction: row;
  white-space: nowrap;
  word-break: break-all;
  color: $black_90;
  overflow-wrap: break-word;

  &::before {
    content: "autorenew";
    animation: spin 1.25s infinite linear;
  }
}

.modal-link-icon {
  top: -22px;
  background-color: theme-color("primary");

  .modal-body & {
    z-index: 1;
  }

  .asset-link-Panel & {
    left: calc(50% - 22px);
  }
}

.dropAdd::before {
  content: $icon_add_circle;
}

.plus-button::before {
  content: $icon_add_circle;
}

.ViewPages {
  &::before {
    content: $icon_library_books;
  }
}

.hasChildren {
  &::before {
    content: $icon_arrow_drop_down;
    transition: transform 0.1s ease-out !important;
    transform: scale(1.5) rotate(-90deg);
  }

  .media-body {
    margin-left: ($spacer / 4);
  }
}

.open > .hasChildren::before {
  transform: scale(1.5) rotate(0deg);
}

.ExpandCollapseButton {
  margin-bottom: 4px;

  &::before {
    content: $icon_arrow_drop_down;
    transition: transform 0.1s ease-out !important;
  }

  &.collapsed::before {
    transform: rotate(-90deg);
  }
}

.BundleManagementIcon-locked {
  &::before {
    content: "lock";
  }
}

.colorSearch {
  > .TopFiveColorStrip {
    width: 100%;
  }
}

.x-grid3-row-checker::before {
  content: $icon_check_box_outline_blank;
}

.BundleManagementIcon-unlocked {
  &::before {
    content: "lock_open";
  }
}

.ConfigureWidgetButton::before {
  content: "dashboard";
}

.ClearButton::before {
  content: "clear";
}

.alignCenterButton::before {
  content: "format_align_center";
}

.alignLeftButton::before {
  content: "format_align_left";
}

.alignRightButton::before {
  content: "format_align_right";
}

.alignTopButton::before {
  content: "vertical_align_top";
}

.alignMiddleButton::before {
  content: "vertical_align_center";
}

.alignBottomButton::before {
  content: "vertical_align_bottom";
}

.editButton-base::before {
  content: "border_color";
}

.Status {
  &::before {
    content: "done";
  }

  &.notAllowed::before {
    color: $red;
  }
}

.sync-problem-icon::before {
  content: "sync_problem";
}

.star:before {
  content: "star_border";
}

.star-full-user:before {
  content: "star";
}

.star-full:before {
  content: "star";
}

.star-half-user:before {
  content: "star_half";
}

.star-half:before {
  content: "star_half";
}

.control-play-triangle:before {
  content: "play_arrow";
}

.processingImage::before {
  content: "autorenew";
  @include absolute-centered($default-icon-font-size-xl);
  animation: spin 1.5s infinite linear;
  opacity: 0.6;

  .tile-view-image-grid & {
    padding: 0;
  }
}

.folder {
  &::before {
    content: $icon_folder;
  }
}

.workflowTrigger::before {
  content: $icon_share;
  color: $color_amulet_approx !important;
}

.deleteButton-base::before {
  content: $icon_cancel;
}

.mbselectionpublic::before {
  content: "supervisor_account";
}

.loadAll::before {
  content: "assignment";
}

.mbselectionshared::before {
  content: "assignment_ind";
}

.mbselectionportals::before {
  content: "filter_drama";
}

.arrow_priority {
  &::before {
    content: $icon_arrow_drop_down;
  }

  &.arrow_priority_up {
    &::before {
      transform: rotate(180deg);
    }
  }
}

.loadButton::before {
  content: "file_upload";
}

.control-stop-square::before {
  content: "stop";
}

.slow::before {
  content: "slow_motion_video";
  transform: rotate(90deg);
}

.fast::before {
  content: "slow_motion_video";
  transform: rotate(180deg);
}

.dropdown-item.file_upload::before {
  content: "file_upload";
}

.dropdown-item.Add-To-Project {
  background-image: url(images/logo-WebCenter.png);
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-position: 24px center;
  display: unset;
  padding-left: 62px;
  .material-icon {
    display: none;
  }
}

.dropdown-item.check::before {
  content: "copyright";
}

.webcenter-icon img {
  display: unset;
  background-image: url(images/logo-WebCenter.png);
  background-repeat: no-repeat;
  background-position: 2px center;
  background-size: 18px 18px;
}

.defaultUserIcon::before {
  content: "person";
}

.dnd-handle::before {
  content: "open_with";
}

.isActive::before {
  content: "check";
}

.addLinkButton-base::before {
  content: "link";
}

.Link::before {
  content: "link";
}

.Delete-link::before {
  content: "delete";
}

.BundleManagementIcon-unlocked-overridden::before {
  content: "lock_open";
}

.view_quilt::before {
  content: "view_quilt";
}

.unlocked::before {
  content: "lock_open";
}

.selectAllButton::before {
  content: "select_all";
}

.Advanced-Search::before {
  content: "search";
}

.advancedsearchName::before {
  content: "search";
}

.Search::before {
  content: "search";
}

.view-demo::before {
  content: "local_movies";
}

.WorkflowButton::before {
  content: "linear_scale";
}

.stampData::before {
  content: "format_paint";
}

.config-button::before {
  content: "settings";
}

.x-tool-gear::before {
  content: "settings";
}

.Direct-download-cm {
  content: "file_download";
}

.download,
.Download-all-cm {
  &::before {
    content: "file_download";
  }
}

.person_add::before {
  content: "person_add";
}

.assessment::before {
  content: "assessment";
}

.enableAll::before {
  content: "highlight";
}

.maximizeButton::before {
  content: "fullscreen";
}

.BasketOptions-base::before {
  min-height: 24px;
  content: "expand_more";
}

.default-metaform-tab > div > h3::before {
  content: "lock";
}

.EmailOutline::before {
  content: "mail_outline";
}

.VideoEditorViewIcon::before {
  content: "theaters";
}

.x-tool-toggle::before {
  content: "expand_less";
}

.x-panel-collapsed .x-tool-toggle::before {
  content: "expand_more";
}

.x-tool-close::before {
  content: $icon_close;
}

.notAllowed::before {
  content: "do_not_disturb";
}

.FullBadgeContainer .Delete:before {
  content: "cancel";
}

.Project > .material-icon,
.Create-project > .material-icon,
.Add-to-project > .material-icon,
.WebCenter > .material-icon {
  opacity: 0;
}

.webcenter-icon > img,
.Project::before,
.Create-project::before,
.Add-to-project::before,
.WebCenter::before {
  background-image: url(images/logo-WebCenter.png);
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-position: 2px center;
  display: unset;
  content: "";
}

//@include include-icon($icon_3d_rotation, true);
//@include include-icon($icon_ac_unit);
//@include include-icon($icon_access_alarm);
//@include include-icon($icon_access_alarms);
//@include include-icon($icon_access_time);
//@include include-icon($icon_accessibility);
//@include include-icon($icon_accessible);
//@include include-icon($icon_account_balance);
//@include include-icon($icon_account_balance_wallet);
//@include include-icon($icon_account_box);
//@include include-icon($icon_account_circle);
//@include include-icon($icon_adb);
//@include include-icon($icon_add);
//@include include-icon($icon_add_a_photo);
//@include include-icon($icon_add_alarm);
//@include include-icon($icon_add_alert);
//@include include-icon($icon_add_box);
//@include include-icon($icon_add_circle);
//@include include-icon($icon_add_circle_outline);
//@include include-icon($icon_add_location);
//@include include-icon($icon_add_shopping_cart);
//@include include-icon($icon_add_to_photos);
//@include include-icon($icon_add_to_queue);
//@include include-icon($icon_adjust);
//@include include-icon($icon_airline_seat_flat);
//@include include-icon($icon_airline_seat_flat_angled);
//@include include-icon($icon_airline_seat_individual_suite);
//@include include-icon($icon_airline_seat_legroom_extra);
//@include include-icon($icon_airline_seat_legroom_normal);
//@include include-icon($icon_airline_seat_legroom_reduced);
//@include include-icon($icon_airline_seat_recline_extra);
//@include include-icon($icon_airline_seat_recline_normal);
//@include include-icon($icon_airplanemode_active);
//@include include-icon($icon_airplanemode_inactive);
//@include include-icon($icon_airplay);
//@include include-icon($icon_airport_shuttle);
//@include include-icon($icon_alarm);
//@include include-icon($icon_alarm_add);
//@include include-icon($icon_alarm_off);
//@include include-icon($icon_alarm_on);
//@include include-icon($icon_album);
//@include include-icon($icon_all_inclusive);
//@include include-icon($icon_all_out);
//@include include-icon($icon_android);
//@include include-icon($icon_announcement);
//@include include-icon($icon_apps);
//@include include-icon($icon_archive);
//@include include-icon($icon_arrow_back);
//@include include-icon($icon_arrow_downward);
@include include-icon($icon_arrow_drop_down, true, before);
//@include include-icon($icon_arrow_drop_down_circle);
//@include include-icon($icon_arrow_drop_up);
//@include include-icon($icon_arrow_forward);
//@include include-icon($icon_arrow_upward);
//@include include-icon($icon_art_track);
//@include include-icon($icon_aspect_ratio);
//@include include-icon($icon_assessment);
//@include include-icon($icon_assignment);
//@include include-icon($icon_assignment_ind);
//@include include-icon($icon_assignment_late);
//@include include-icon($icon_assignment_return);
//@include include-icon($icon_assignment_returned);
//@include include-icon($icon_assignment_turned_in);
//@include include-icon($icon_assistant);
//@include include-icon($icon_assistant_photo);
//@include include-icon($icon_attach_file);
//@include include-icon($icon_attach_money);
//@include include-icon($icon_attachment);
//@include include-icon($icon_audiotrack);
//@include include-icon($icon_autorenew);
//@include include-icon($icon_av_timer);
//@include include-icon($icon_backspace);
//@include include-icon($icon_backup);
//@include include-icon($icon_battery_alert);
//@include include-icon($icon_battery_charging_full);
//@include include-icon($icon_battery_full);
//@include include-icon($icon_battery_std);
//@include include-icon($icon_battery_unknown);
//@include include-icon($icon_beach_access);
//@include include-icon($icon_beenhere);
//@include include-icon($icon_block);
//@include include-icon($icon_bluetooth);
//@include include-icon($icon_bluetooth_audio);
//@include include-icon($icon_bluetooth_connected);
//@include include-icon($icon_bluetooth_disabled);
//@include include-icon($icon_bluetooth_searching);
//@include include-icon($icon_blur_circular);
//@include include-icon($icon_blur_linear);
//@include include-icon($icon_blur_off);
//@include include-icon($icon_blur_on);
//@include include-icon($icon_book);
//@include include-icon($icon_bookmark);
//@include include-icon($icon_bookmark_border);
//@include include-icon($icon_border_all);
//@include include-icon($icon_border_bottom);
//@include include-icon($icon_border_clear);
//@include include-icon($icon_border_color);
//@include include-icon($icon_border_horizontal);
//@include include-icon($icon_border_inner);
//@include include-icon($icon_border_left);
//@include include-icon($icon_border_outer);
//@include include-icon($icon_border_right);
//@include include-icon($icon_border_style);
//@include include-icon($icon_border_top);
//@include include-icon($icon_border_vertical);
//@include include-icon($icon_branding_watermark);
//@include include-icon($icon_brightness_1);
//@include include-icon($icon_brightness_2);
//@include include-icon($icon_brightness_3);
//@include include-icon($icon_brightness_4);
//@include include-icon($icon_brightness_5);
//@include include-icon($icon_brightness_6);
//@include include-icon($icon_brightness_7);
//@include include-icon($icon_brightness_auto);
//@include include-icon($icon_brightness_high);
//@include include-icon($icon_brightness_low);
//@include include-icon($icon_brightness_medium);
//@include include-icon($icon_broken_image);
//@include include-icon($icon_brush);
//@include include-icon($icon_bubble_chart);
//@include include-icon($icon_bug_report);
//@include include-icon($icon_build);
//@include include-icon($icon_burst_mode);
//@include include-icon($icon_business);
//@include include-icon($icon_business_center);
//@include include-icon($icon_cached);
//@include include-icon($icon_cake);
//@include include-icon($icon_call);
//@include include-icon($icon_call_end);
//@include include-icon($icon_call_made);
//@include include-icon($icon_call_merge);
//@include include-icon($icon_call_missed);
//@include include-icon($icon_call_missed_outgoing);
//@include include-icon($icon_call_received);
//@include include-icon($icon_call_split);
//@include include-icon($icon_call_to_action);
//@include include-icon($icon_camera);
//@include include-icon($icon_camera_alt);
//@include include-icon($icon_camera_enhance);
//@include include-icon($icon_camera_front);
//@include include-icon($icon_camera_rear);
//@include include-icon($icon_camera_roll);
//@include include-icon($icon_cancel);
//@include include-icon($icon_card_giftcard);
//@include include-icon($icon_card_membership);
//@include include-icon($icon_card_travel);
//@include include-icon($icon_casino);
//@include include-icon($icon_cast);
//@include include-icon($icon_cast_connected);
//@include include-icon($icon_center_focus_strong);
//@include include-icon($icon_center_focus_weak);
//@include include-icon($icon_change_history);
//@include include-icon($icon_chat);
//@include include-icon($icon_chat_bubble);
//@include include-icon($icon_chat_bubble_outline);
//@include include-icon($icon_check);
//@include include-icon($icon_check_box);
//@include include-icon($icon_check_box_outline_blank);
//@include include-icon($icon_check_circle);
//@include include-icon($icon_chevron_left);
//@include include-icon($icon_chevron_right);
//@include include-icon($icon_child_care);
//@include include-icon($icon_child_friendly);
//@include include-icon($icon_chrome_reader_mode);
//@include include-icon($icon_class);
//@include include-icon($icon_clear);
//@include include-icon($icon_clear_all);
//@include include-icon($icon_clear_field);
@include include-icon($icon_close, true, before);
//@include include-icon($icon_closed_caption);
//@include include-icon($icon_cloud);
//@include include-icon($icon_cloud_circle);
//@include include-icon($icon_cloud_done);
//@include include-icon($icon_cloud_download);
//@include include-icon($icon_cloud_off);
//@include include-icon($icon_cloud_queue);
//@include include-icon($icon_cloud_upload);
//@include include-icon($icon_code);
//@include include-icon($icon_collections);
//@include include-icon($icon_collections_bookmark);
//@include include-icon($icon_color_lens);
//@include include-icon($icon_colorize);
//@include include-icon($icon_comment);
//@include include-icon($icon_compare);
//@include include-icon($icon_compare_arrows);
//@include include-icon($icon_computer);
//@include include-icon($icon_confirmation_number);
//@include include-icon($icon_contact_mail);
//@include include-icon($icon_contact_phone);
//@include include-icon($icon_contacts);
//@include include-icon($icon_content_copy);
//@include include-icon($icon_content_cut);
//@include include-icon($icon_content_paste);
//@include include-icon($icon_control_point);
//@include include-icon($icon_control_point_duplicate);
//@include include-icon($icon_copyright);
//@include include-icon($icon_create);
//@include include-icon($icon_create_new_folder);
//@include include-icon($icon_credit_card);
//@include include-icon($icon_crop);
//@include include-icon($icon_crop_16_9);
//@include include-icon($icon_crop_3_2);
//@include include-icon($icon_crop_5_4);
//@include include-icon($icon_crop_7_5);
//@include include-icon($icon_crop_din);
//@include include-icon($icon_crop_free);
//@include include-icon($icon_crop_landscape);
//@include include-icon($icon_crop_original);
//@include include-icon($icon_crop_portrait);
//@include include-icon($icon_crop_rotate);
//@include include-icon($icon_crop_square);
//@include include-icon($icon_dashboard);
//@include include-icon($icon_data_usage);
//@include include-icon($icon_date_range);
//@include include-icon($icon_dehaze);
//@include include-icon($icon_delete);
//@include include-icon($icon_delete_forever);
//@include include-icon($icon_delete_sweep);
//@include include-icon($icon_description);
//@include include-icon($icon_desktop_mac);
//@include include-icon($icon_desktop_windows);
//@include include-icon($icon_details);
//@include include-icon($icon_developer_board);
//@include include-icon($icon_developer_mode);
//@include include-icon($icon_device_hub);
//@include include-icon($icon_devices);
//@include include-icon($icon_devices_other);
//@include include-icon($icon_dialer_sip);
//@include include-icon($icon_dialpad);
//@include include-icon($icon_directions);
//@include include-icon($icon_directions_bike);
//@include include-icon($icon_directions_boat);
//@include include-icon($icon_directions_bus);
//@include include-icon($icon_directions_car);
//@include include-icon($icon_directions_railway);
//@include include-icon($icon_directions_run);
//@include include-icon($icon_directions_subway);
//@include include-icon($icon_directions_transit);
//@include include-icon($icon_directions_walk);
//@include include-icon($icon_disc_full);
//@include include-icon($icon_dns);
//@include include-icon($icon_do_not_disturb);
//@include include-icon($icon_do_not_disturb_alt);
//@include include-icon($icon_do_not_disturb_off);
//@include include-icon($icon_do_not_disturb_on);
//@include include-icon($icon_dock);
//@include include-icon($icon_domain);
//@include include-icon($icon_done);
//@include include-icon($icon_done_all);
//@include include-icon($icon_donut_large);
//@include include-icon($icon_donut_small);
//@include include-icon($icon_drafts);
//@include include-icon($icon_drag_handle);
//@include include-icon($icon_drive_eta);
//@include include-icon($icon_dvr);
//@include include-icon($icon_edit);
//@include include-icon($icon_edit_location);
//@include include-icon($icon_eject);
//@include include-icon($icon_email);
//@include include-icon($icon_enhanced_encryption);
//@include include-icon($icon_equalizer);
//@include include-icon($icon_error);
//@include include-icon($icon_error_outline);
//@include include-icon($icon_euro_symbol);
//@include include-icon($icon_ev_station);
//@include include-icon($icon_event);
//@include include-icon($icon_event_available);
//@include include-icon($icon_event_busy);
//@include include-icon($icon_event_note);
//@include include-icon($icon_event_seat);
//@include include-icon($icon_exit_to_app);
//@include include-icon($icon_expand_less);
//@include include-icon($icon_expand_more);
//@include include-icon($icon_explicit);
//@include include-icon($icon_explore);
//@include include-icon($icon_exposure);
//@include include-icon($icon_exposure_neg_1);
//@include include-icon($icon_exposure_neg_2);
//@include include-icon($icon_exposure_plus_1);
//@include include-icon($icon_exposure_plus_2);
//@include include-icon($icon_exposure_zero);
//@include include-icon($icon_extension);
//@include include-icon($icon_face);
//@include include-icon($icon_fast_forward);
//@include include-icon($icon_fast_rewind);
//@include include-icon($icon_favorite);
//@include include-icon($icon_favorite_border);
//@include include-icon($icon_featured_play_list);
//@include include-icon($icon_featured_video);
//@include include-icon($icon_feedback);
//@include include-icon($icon_fiber_dvr);
//@include include-icon($icon_fiber_manual_record);
//@include include-icon($icon_fiber_new);
//@include include-icon($icon_fiber_pin);
//@include include-icon($icon_fiber_smart_record);
//@include include-icon($icon_file_download);
//@include include-icon($icon_file_upload);
//@include include-icon($icon_filter);
//@include include-icon($icon_filter_1);
//@include include-icon($icon_filter_2);
//@include include-icon($icon_filter_3);
//@include include-icon($icon_filter_4);
//@include include-icon($icon_filter_5);
//@include include-icon($icon_filter_6);
//@include include-icon($icon_filter_7);
//@include include-icon($icon_filter_8);
//@include include-icon($icon_filter_9);
//@include include-icon($icon_filter_9_plus);
//@include include-icon($icon_filter_b_and_w);
//@include include-icon($icon_filter_center_focus);
//@include include-icon($icon_filter_drama);
//@include include-icon($icon_filter_frames);
//@include include-icon($icon_filter_hdr);
//@include include-icon($icon_filter_list);
//@include include-icon($icon_filter_none);
//@include include-icon($icon_filter_tilt_shift);
//@include include-icon($icon_filter_vintage);
//@include include-icon($icon_find_in_page);
//@include include-icon($icon_find_replace);
//@include include-icon($icon_fingerprint);
//@include include-icon($icon_first_page);
//@include include-icon($icon_fitness_center);
//@include include-icon($icon_flag);
//@include include-icon($icon_flare);
//@include include-icon($icon_flash_auto);
//@include include-icon($icon_flash_off);
//@include include-icon($icon_flash_on);
//@include include-icon($icon_flight);
//@include include-icon($icon_flight_land);
//@include include-icon($icon_flight_takeoff);
//@include include-icon($icon_flip);
//@include include-icon($icon_flip_to_back);
//@include include-icon($icon_flip_to_front);
//@include include-icon($icon_folder);
//@include include-icon($icon_folder_open);
//@include include-icon($icon_folder_shared);
//@include include-icon($icon_folder_special);
//@include include-icon($icon_font_download);
//@include include-icon($icon_format_align_center);
//@include include-icon($icon_format_align_justify);
//@include include-icon($icon_format_align_left);
//@include include-icon($icon_format_align_right);
//@include include-icon($icon_format_bold);
//@include include-icon($icon_format_clear);
//@include include-icon($icon_format_color_fill);
//@include include-icon($icon_format_color_reset);
//@include include-icon($icon_format_color_text);
//@include include-icon($icon_format_indent_decrease);
//@include include-icon($icon_format_indent_increase);
//@include include-icon($icon_format_italic);
//@include include-icon($icon_format_line_spacing);
//@include include-icon($icon_format_list_bulleted);
//@include include-icon($icon_format_list_numbered);
//@include include-icon($icon_format_paint);
//@include include-icon($icon_format_quote);
//@include include-icon($icon_format_shapes);
//@include include-icon($icon_format_size);
//@include include-icon($icon_format_strikethrough);
//@include include-icon($icon_format_textdirection_l_to_r);
//@include include-icon($icon_format_textdirection_r_to_l);
//@include include-icon($icon_format_underlined);
//@include include-icon($icon_forum);
//@include include-icon($icon_forward);
//@include include-icon($icon_forward_10);
//@include include-icon($icon_forward_30);
//@include include-icon($icon_forward_5);
//@include include-icon($icon_free_breakfast);
//@include include-icon($icon_fullscreen);
//@include include-icon($icon_fullscreen_exit);
//@include include-icon($icon_functions);
//@include include-icon($icon_g_translate);
//@include include-icon($icon_gamepad);
//@include include-icon($icon_games);
//@include include-icon($icon_gavel);
//@include include-icon($icon_gesture);
//@include include-icon($icon_get_app);
//@include include-icon($icon_gif);
//@include include-icon($icon_golf_course);
//@include include-icon($icon_gps_fixed);
//@include include-icon($icon_gps_not_fixed);
//@include include-icon($icon_gps_off);
//@include include-icon($icon_grade);
//@include include-icon($icon_gradient);
//@include include-icon($icon_grain);
//@include include-icon($icon_graphic_eq);
//@include include-icon($icon_grid_off);
//@include include-icon($icon_grid_on);
//@include include-icon($icon_group);
//@include include-icon($icon_group_add);
//@include include-icon($icon_group_work);
//@include include-icon($icon_hd);
//@include include-icon($icon_hdr_off);
//@include include-icon($icon_hdr_on);
//@include include-icon($icon_hdr_strong);
//@include include-icon($icon_hdr_weak);
//@include include-icon($icon_headset);
//@include include-icon($icon_headset_mic);
//@include include-icon($icon_healing);
//@include include-icon($icon_hearing);
//@include include-icon($icon_help);
//@include include-icon($icon_help_outline);
//@include include-icon($icon_high_quality);
//@include include-icon($icon_highlight);
//@include include-icon($icon_highlight_off);
//@include include-icon($icon_history);
//@include include-icon($icon_home);
//@include include-icon($icon_hot_tub);
//@include include-icon($icon_hotel);
//@include include-icon($icon_hourglass_empty);
//@include include-icon($icon_hourglass_full);
//@include include-icon($icon_http);
//@include include-icon($icon_https);
//@include include-icon($icon_image);
//@include include-icon($icon_image_aspect_ratio);
//@include include-icon($icon_import_contacts);
//@include include-icon($icon_import_export);
//@include include-icon($icon_important_devices);
//@include include-icon($icon_inbox);
//@include include-icon($icon_indeterminate_check_box);
//@include include-icon($icon_info);
//@include include-icon($icon_info_outline);
//@include include-icon($icon_input);
//@include include-icon($icon_insert_chart);
//@include include-icon($icon_insert_comment);
//@include include-icon($icon_insert_drive_file);
//@include include-icon($icon_insert_emoticon);
//@include include-icon($icon_insert_invitation);
//@include include-icon($icon_insert_link);
//@include include-icon($icon_insert_photo);
//@include include-icon($icon_invert_colors);
//@include include-icon($icon_invert_colors_off);
//@include include-icon($icon_iso);
//@include include-icon($icon_keyboard);
//@include include-icon($icon_keyboard_arrow_down);
//@include include-icon($icon_keyboard_arrow_left);
//@include include-icon($icon_keyboard_arrow_right);
//@include include-icon($icon_keyboard_arrow_up);
//@include include-icon($icon_keyboard_backspace);
//@include include-icon($icon_keyboard_capslock);
//@include include-icon($icon_keyboard_hide);
//@include include-icon($icon_keyboard_return);
//@include include-icon($icon_keyboard_tab);
//@include include-icon($icon_keyboard_voice);
//@include include-icon($icon_kitchen);
//@include include-icon($icon_label);
//@include include-icon($icon_label_outline);
//@include include-icon($icon_landscape);
//@include include-icon($icon_language);
//@include include-icon($icon_laptop);
//@include include-icon($icon_laptop_chromebook);
//@include include-icon($icon_laptop_mac);
//@include include-icon($icon_laptop_windows);
//@include include-icon($icon_last_page);
//@include include-icon($icon_launch);
//@include include-icon($icon_layers);
//@include include-icon($icon_layers_clear);
//@include include-icon($icon_leak_add);
//@include include-icon($icon_leak_remove);
//@include include-icon($icon_lens);
//@include include-icon($icon_library_add);
//@include include-icon($icon_library_books);
//@include include-icon($icon_library_music);
//@include include-icon($icon_lightbulb_outline);
//@include include-icon($icon_line_style);
//@include include-icon($icon_line_weight);
//@include include-icon($icon_linear_scale);
//@include include-icon($icon_link);
//@include include-icon($icon_linked_camera);
//@include include-icon($icon_list);
//@include include-icon($icon_live_help);
//@include include-icon($icon_live_tv);
//@include include-icon($icon_local_activity);
//@include include-icon($icon_local_airport);
//@include include-icon($icon_local_atm);
//@include include-icon($icon_local_bar);
//@include include-icon($icon_local_cafe);
//@include include-icon($icon_local_car_wash);
//@include include-icon($icon_local_convenience_store);
//@include include-icon($icon_local_dining);
//@include include-icon($icon_local_drink);
//@include include-icon($icon_local_florist);
//@include include-icon($icon_local_gas_station);
//@include include-icon($icon_local_grocery_store);
//@include include-icon($icon_local_hospital);
//@include include-icon($icon_local_hotel);
//@include include-icon($icon_local_laundry_service);
//@include include-icon($icon_local_library);
//@include include-icon($icon_local_mall);
//@include include-icon($icon_local_movies);
//@include include-icon($icon_local_offer);
//@include include-icon($icon_local_parking);
//@include include-icon($icon_local_pharmacy);
//@include include-icon($icon_local_phone);
//@include include-icon($icon_local_pizza);
//@include include-icon($icon_local_play);
//@include include-icon($icon_local_post_office);
//@include include-icon($icon_local_printshop);
//@include include-icon($icon_local_see);
//@include include-icon($icon_local_shipping);
//@include include-icon($icon_local_taxi);
//@include include-icon($icon_location_city);
//@include include-icon($icon_location_disabled);
//@include include-icon($icon_location_off);
//@include include-icon($icon_location_on);
//@include include-icon($icon_location_searching);
//@include include-icon($icon_lock);
//@include include-icon($icon_lock_open);
//@include include-icon($icon_lock_outline);
//@include include-icon($icon_looks);
//@include include-icon($icon_looks_3);
//@include include-icon($icon_looks_4);
//@include include-icon($icon_looks_5);
//@include include-icon($icon_looks_6);
//@include include-icon($icon_looks_one);
//@include include-icon($icon_looks_two);
//@include include-icon($icon_loop);
//@include include-icon($icon_loupe);
//@include include-icon($icon_low_priority);
//@include include-icon($icon_loyalty);
//@include include-icon($icon_mail);
//@include include-icon($icon_mail_outline);
//@include include-icon($icon_map);
//@include include-icon($icon_markunread);
//@include include-icon($icon_markunread_mailbox);
//@include include-icon($icon_memory);
//@include include-icon($icon_menu);
//@include include-icon($icon_merge_type);
//@include include-icon($icon_message);
//@include include-icon($icon_mic);
//@include include-icon($icon_mic_none);
//@include include-icon($icon_mic_off);
//@include include-icon($icon_mms);
//@include include-icon($icon_mode_comment);
//@include include-icon($icon_mode_edit);
//@include include-icon($icon_monetization_on);
//@include include-icon($icon_money_off);
//@include include-icon($icon_monochrome_photos);
//@include include-icon($icon_mood);
//@include include-icon($icon_mood_bad);
//@include include-icon($icon_more);
//@include include-icon($icon_more_horiz);
//@include include-icon($icon_more_vert);
//@include include-icon($icon_motorcycle);
//@include include-icon($icon_mouse);
//@include include-icon($icon_move_to_inbox);
//@include include-icon($icon_movie);
//@include include-icon($icon_movie_creation);
//@include include-icon($icon_movie_filter);
//@include include-icon($icon_multiline_chart);
//@include include-icon($icon_music_note);
//@include include-icon($icon_music_video);
//@include include-icon($icon_my_location);
//@include include-icon($icon_nature);
//@include include-icon($icon_nature_people);
//@include include-icon($icon_navigate_before);
//@include include-icon($icon_navigate_next);
//@include include-icon($icon_navigation);
//@include include-icon($icon_near_me);
//@include include-icon($icon_network_cell);
//@include include-icon($icon_network_check);
//@include include-icon($icon_network_locked);
//@include include-icon($icon_network_wifi);
//@include include-icon($icon_new_releases);
//@include include-icon($icon_next_week);
//@include include-icon($icon_nfc);
//@include include-icon($icon_no_encryption);
//@include include-icon($icon_no_sim);
//@include include-icon($icon_not_interested);
//@include include-icon($icon_note);
//@include include-icon($icon_note_add);
//@include include-icon($icon_notifications);
//@include include-icon($icon_notifications_active);
//@include include-icon($icon_notifications_none);
//@include include-icon($icon_notifications_off);
//@include include-icon($icon_notifications_paused);
//@include include-icon($icon_offline_pin);
//@include include-icon($icon_ondemand_video);
//@include include-icon($icon_opacity);
//@include include-icon($icon_open_in_browser);
//@include include-icon($icon_open_in_new);
//@include include-icon($icon_open_with);
//@include include-icon($icon_pages);
//@include include-icon($icon_pageview);
//@include include-icon($icon_palette);
//@include include-icon($icon_pan_tool);
//@include include-icon($icon_panorama);
//@include include-icon($icon_panorama_fish_eye);
//@include include-icon($icon_panorama_horizontal);
//@include include-icon($icon_panorama_vertical);
//@include include-icon($icon_panorama_wide_angle);
//@include include-icon($icon_party_mode);
//@include include-icon($icon_pause);
//@include include-icon($icon_pause_circle_filled);
//@include include-icon($icon_pause_circle_outline);
//@include include-icon($icon_payment);
//@include include-icon($icon_people);
//@include include-icon($icon_people_outline);
//@include include-icon($icon_perm_camera_mic);
//@include include-icon($icon_perm_contact_calendar);
//@include include-icon($icon_perm_data_setting);
//@include include-icon($icon_perm_device_information);
//@include include-icon($icon_perm_identity);
//@include include-icon($icon_perm_media);
//@include include-icon($icon_perm_phone_msg);
//@include include-icon($icon_perm_scan_wifi);
//@include include-icon($icon_person);
//@include include-icon($icon_person_add);
//@include include-icon($icon_person_outline);
//@include include-icon($icon_person_pin);
//@include include-icon($icon_person_pin_circle);
//@include include-icon($icon_personal_video);
//@include include-icon($icon_pets);
//@include include-icon($icon_phone);
//@include include-icon($icon_phone_android);
//@include include-icon($icon_phone_bluetooth_speaker);
//@include include-icon($icon_phone_forwarded);
//@include include-icon($icon_phone_in_talk);
//@include include-icon($icon_phone_iphone);
//@include include-icon($icon_phone_locked);
//@include include-icon($icon_phone_missed);
//@include include-icon($icon_phone_paused);
//@include include-icon($icon_phonelink);
//@include include-icon($icon_phonelink_erase);
//@include include-icon($icon_phonelink_lock);
//@include include-icon($icon_phonelink_off);
//@include include-icon($icon_phonelink_ring);
//@include include-icon($icon_phonelink_setup);
//@include include-icon($icon_photo);
//@include include-icon($icon_photo_album);
//@include include-icon($icon_photo_camera);
//@include include-icon($icon_photo_filter);
//@include include-icon($icon_photo_library);
//@include include-icon($icon_photo_size_select_actual);
//@include include-icon($icon_photo_size_select_large);
//@include include-icon($icon_photo_size_select_small);
//@include include-icon($icon_picture_as_pdf);
//@include include-icon($icon_picture_in_picture);
//@include include-icon($icon_picture_in_picture_alt);
//@include include-icon($icon_pie_chart);
//@include include-icon($icon_pie_chart_outlined);
//@include include-icon($icon_pin_drop);
//@include include-icon($icon_place);
//@include include-icon($icon_play_arrow);
//@include include-icon($icon_play_circle_filled);
//@include include-icon($icon_play_circle_outline);
//@include include-icon($icon_play_for_work);
//@include include-icon($icon_playlist_add);
//@include include-icon($icon_playlist_add_check);
//@include include-icon($icon_playlist_play);
//@include include-icon($icon_plus_one);
//@include include-icon($icon_poll);
//@include include-icon($icon_polymer);
//@include include-icon($icon_pool);
//@include include-icon($icon_portable_wifi_off);
//@include include-icon($icon_portrait);
//@include include-icon($icon_power);
//@include include-icon($icon_power_input);
//@include include-icon($icon_power_settings_new);
//@include include-icon($icon_pregnant_woman);
//@include include-icon($icon_present_to_all);
//@include include-icon($icon_print);
//@include include-icon($icon_priority_high);
//@include include-icon($icon_public);
//@include include-icon($icon_publish);
//@include include-icon($icon_query_builder);
//@include include-icon($icon_question_answer);
//@include include-icon($icon_queue);
//@include include-icon($icon_queue_music);
//@include include-icon($icon_queue_play_next);
//@include include-icon($icon_radio);
//@include include-icon($icon_radio_button_checked);
//@include include-icon($icon_radio_button_unchecked);
//@include include-icon($icon_rate_review);
//@include include-icon($icon_receipt);
//@include include-icon($icon_recent_actors);
//@include include-icon($icon_record_voice_over);
//@include include-icon($icon_redeem);
//@include include-icon($icon_redo);
//@include include-icon($icon_refresh);
//@include include-icon($icon_remove);
//@include include-icon($icon_remove_circle);
//@include include-icon($icon_remove_circle_outline);
//@include include-icon($icon_remove_from_queue);
//@include include-icon($icon_remove_red_eye);
//@include include-icon($icon_remove_shopping_cart);
//@include include-icon($icon_reorder);
//@include include-icon($icon_repeat);
//@include include-icon($icon_repeat_one);
//@include include-icon($icon_replay);
//@include include-icon($icon_replay_10);
//@include include-icon($icon_replay_30);
//@include include-icon($icon_replay_5);
//@include include-icon($icon_reply);
//@include include-icon($icon_reply_all);
//@include include-icon($icon_report);
//@include include-icon($icon_report_problem);
//@include include-icon($icon_restaurant);
//@include include-icon($icon_restaurant_menu);
//@include include-icon($icon_restore);
//@include include-icon($icon_restore_page);
//@include include-icon($icon_ring_volume);
//@include include-icon($icon_room);
//@include include-icon($icon_room_service);
//@include include-icon($icon_rotate_90_degrees_ccw);
//@include include-icon($icon_rotate_left);
//@include include-icon($icon_rotate_right);
//@include include-icon($icon_rounded_corner);
//@include include-icon($icon_router);
//@include include-icon($icon_rowing);
//@include include-icon($icon_rss_feed);
//@include include-icon($icon_rv_hookup);
//@include include-icon($icon_satellite);
//@include include-icon($icon_save);
//@include include-icon($icon_scanner);
//@include include-icon($icon_schedule);
//@include include-icon($icon_school);
//@include include-icon($icon_screen_lock_landscape);
//@include include-icon($icon_screen_lock_portrait);
//@include include-icon($icon_screen_lock_rotation);
//@include include-icon($icon_screen_rotation);
//@include include-icon($icon_screen_share);
//@include include-icon($icon_sd_card);
//@include include-icon($icon_sd_storage);
//@include include-icon($icon_search);
//@include include-icon($icon_security);
//@include include-icon($icon_select_all);
//@include include-icon($icon_send);
//@include include-icon($icon_sentiment_dissatisfied);
//@include include-icon($icon_sentiment_neutral);
//@include include-icon($icon_sentiment_satisfied);
//@include include-icon($icon_sentiment_very_dissatisfied);
//@include include-icon($icon_sentiment_very_satisfied);
//@include include-icon($icon_settings);
//@include include-icon($icon_settings_applications);
//@include include-icon($icon_settings_backup_restore);
//@include include-icon($icon_settings_bluetooth);
//@include include-icon($icon_settings_brightness);
//@include include-icon($icon_settings_cell);
//@include include-icon($icon_settings_ethernet);
//@include include-icon($icon_settings_input_antenna);
//@include include-icon($icon_settings_input_component);
//@include include-icon($icon_settings_input_composite);
//@include include-icon($icon_settings_input_hdmi);
//@include include-icon($icon_settings_input_svideo);
//@include include-icon($icon_settings_overscan);
//@include include-icon($icon_settings_phone);
//@include include-icon($icon_settings_power);
//@include include-icon($icon_settings_remote);
//@include include-icon($icon_settings_system_daydream);
//@include include-icon($icon_settings_voice);
//@include include-icon($icon_share);
//@include include-icon($icon_shop);
//@include include-icon($icon_shop_two);
//@include include-icon($icon_shopping_basket);
//@include include-icon($icon_shopping_cart);
//@include include-icon($icon_short_text);
//@include include-icon($icon_show_chart);
//@include include-icon($icon_shuffle);
//@include include-icon($icon_signal_cellular_4_bar);
//@include include-icon($icon_signal_cellular_connected_no_internet_4_bar);
//@include include-icon($icon_signal_cellular_no_sim);
//@include include-icon($icon_signal_cellular_null);
//@include include-icon($icon_signal_cellular_off);
//@include include-icon($icon_signal_wifi_4_bar);
//@include include-icon($icon_signal_wifi_4_bar_lock);
//@include include-icon($icon_signal_wifi_off);
//@include include-icon($icon_sim_card);
//@include include-icon($icon_sim_card_alert);
//@include include-icon($icon_skip_next);
//@include include-icon($icon_skip_previous);
//@include include-icon($icon_slideshow);
//@include include-icon($icon_slow_motion_video);
//@include include-icon($icon_smartphone);
//@include include-icon($icon_smoke_free);
//@include include-icon($icon_smoking_rooms);
//@include include-icon($icon_sms);
//@include include-icon($icon_sms_failed);
//@include include-icon($icon_snooze);
//@include include-icon($icon_sort);
//@include include-icon($icon_sort_by_alpha);
//@include include-icon($icon_spa);
//@include include-icon($icon_space_bar);
//@include include-icon($icon_speaker);
//@include include-icon($icon_speaker_group);
//@include include-icon($icon_speaker_notes);
//@include include-icon($icon_speaker_notes_off);
//@include include-icon($icon_speaker_phone);
//@include include-icon($icon_spellcheck);
//@include include-icon($icon_star);
//@include include-icon($icon_star_border);
//@include include-icon($icon_star_half);
//@include include-icon($icon_stars);
//@include include-icon($icon_stay_current_landscape);
//@include include-icon($icon_stay_current_portrait);
//@include include-icon($icon_stay_primary_landscape);
//@include include-icon($icon_stay_primary_portrait);
//@include include-icon($icon_stop);
//@include include-icon($icon_stop_screen_share);
//@include include-icon($icon_storage);
//@include include-icon($icon_store);
//@include include-icon($icon_store_mall_directory);
//@include include-icon($icon_straighten);
//@include include-icon($icon_streetview);
//@include include-icon($icon_strikethrough_s);
//@include include-icon($icon_style);
//@include include-icon($icon_subdirectory_arrow_left);
//@include include-icon($icon_subdirectory_arrow_right);
//@include include-icon($icon_subject);
//@include include-icon($icon_subscriptions);
//@include include-icon($icon_subtitles);
//@include include-icon($icon_subway);
//@include include-icon($icon_supervisor_account);
//@include include-icon($icon_surround_sound);
//@include include-icon($icon_swap_calls);
//@include include-icon($icon_swap_horiz);
//@include include-icon($icon_swap_vert);
//@include include-icon($icon_swap_vertical_circle);
//@include include-icon($icon_switch_camera);
//@include include-icon($icon_switch_video);
//@include include-icon($icon_sync);
//@include include-icon($icon_sync_disabled);
//@include include-icon($icon_sync_problem);
//@include include-icon($icon_system_update);
//@include include-icon($icon_system_update_alt);
//@include include-icon($icon_tab);
//@include include-icon($icon_tab_unselected);
//@include include-icon($icon_tablet);
//@include include-icon($icon_tablet_android);
//@include include-icon($icon_tablet_mac);
//@include include-icon($icon_tag_faces);
//@include include-icon($icon_tap_and_play);
//@include include-icon($icon_terrain);
//@include include-icon($icon_text_fields);
//@include include-icon($icon_text_format);
//@include include-icon($icon_textsms);
//@include include-icon($icon_texture);
//@include include-icon($icon_theaters);
//@include include-icon($icon_thumb_down);
//@include include-icon($icon_thumb_up);
//@include include-icon($icon_thumbs_up_down);
//@include include-icon($icon_time_to_leave);
//@include include-icon($icon_timelapse);
//@include include-icon($icon_timeline);
//@include include-icon($icon_timer);
//@include include-icon($icon_timer_10);
//@include include-icon($icon_timer_3);
//@include include-icon($icon_timer_off);
//@include include-icon($icon_title);
//@include include-icon($icon_toc);
//@include include-icon($icon_today);
//@include include-icon($icon_toll);
//@include include-icon($icon_tonality);
//@include include-icon($icon_touch_app);
//@include include-icon($icon_toys);
//@include include-icon($icon_track_changes);
//@include include-icon($icon_traffic);
//@include include-icon($icon_train);
//@include include-icon($icon_tram);
//@include include-icon($icon_transfer_within_a_station);
//@include include-icon($icon_transform);
//@include include-icon($icon_translate);
//@include include-icon($icon_trending_down);
//@include include-icon($icon_trending_flat);
//@include include-icon($icon_trending_up);
//@include include-icon($icon_tune);
//@include include-icon($icon_turned_in);
//@include include-icon($icon_turned_in_not);
//@include include-icon($icon_tv);
//@include include-icon($icon_unarchive);
//@include include-icon($icon_undo);
//@include include-icon($icon_unfold_less);
//@include include-icon($icon_unfold_more);
//@include include-icon($icon_update);
//@include include-icon($icon_usb);
//@include include-icon($icon_verified_user);
//@include include-icon($icon_vertical_align_bottom);
//@include include-icon($icon_vertical_align_center);
//@include include-icon($icon_vertical_align_top);
//@include include-icon($icon_vibration);
//@include include-icon($icon_video_call);
//@include include-icon($icon_video_label);
//@include include-icon($icon_video_library);
//@include include-icon($icon_videocam);
//@include include-icon($icon_videocam_off);
//@include include-icon($icon_videogame_asset);
//@include include-icon($icon_view_agenda);
//@include include-icon($icon_view_array);
//@include include-icon($icon_view_carousel);
//@include include-icon($icon_view_column);
//@include include-icon($icon_view_comfy);
//@include include-icon($icon_view_compact);
//@include include-icon($icon_view_day);
//@include include-icon($icon_view_headline);
//@include include-icon($icon_view_list);
//@include include-icon($icon_view_module);
//@include include-icon($icon_view_quilt);
//@include include-icon($icon_view_stream);
//@include include-icon($icon_view_week);
//@include include-icon($icon_vignette);
//@include include-icon($icon_visibility);
//@include include-icon($icon_visibility_off);
//@include include-icon($icon_voice_chat);
//@include include-icon($icon_voicemail);
//@include include-icon($icon_volume_down);
//@include include-icon($icon_volume_mute);
//@include include-icon($icon_volume_off);
//@include include-icon($icon_volume_up);
//@include include-icon($icon_vpn_key);
//@include include-icon($icon_vpn_lock);
//@include include-icon($icon_wallpaper);
//@include include-icon($icon_warning);
//@include include-icon($icon_watch);
//@include include-icon($icon_watch_later);
//@include include-icon($icon_wb_auto);
//@include include-icon($icon_wb_cloudy);
//@include include-icon($icon_wb_incandescent);
//@include include-icon($icon_wb_iridescent);
//@include include-icon($icon_wb_sunny);
//@include include-icon($icon_wc);
//@include include-icon($icon_web);
//@include include-icon($icon_web_asset);
//@include include-icon($icon_weekend);
//@include include-icon($icon_whatshot);
//@include include-icon($icon_widgets);
//@include include-icon($icon_wifi);
//@include include-icon($icon_wifi_lock);
//@include include-icon($icon_wifi_tethering);
//@include include-icon($icon_work);
//@include include-icon($icon_wrap_text);
//@include include-icon($icon_youtube_searched_for);
//@include include-icon($icon_zoom_in);
//@include include-icon($icon_zoom_out);
//@include include-icon($icon_zoom_out_map);
