// stylelint-disable selector-no-qualifying-type
.btn {
  position: relative;
  // Turn off pointer events to event can bubble to btn
  svg {
    pointer-events: none;
  }

  &.star-full {
    &::before {
      color: #eb6e00;
    }
  }

  .material-icon {
    pointer-events: none;
    color: currentColor;
  }
}

.MainPage {
  .ImportExportHolderPanel {
    .btn {
      @extend .btn-sm;
    }
  }
}

// Catch all for buttons that are not <button>'s
div.btn,
[role="button"] {
  // Opinionated: add "hand" cursor to non-disabled .btn elements
  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  .disabled & {
    cursor: default;
  }
}

// Future-proof disabling of clicks on `<a>` elements
div.btn.disabled,
fieldset[disabled] div.btn {
  pointer-events: none;
}

.btn-no-focus {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.btn {
  &.has-icon-before::before,
  &.has-icon-after::after {
    transform: scale(1.5);
  }

  &:not(:disabled):not(.disabled) {
    &.sync-problem-icon {
      &::before {
        @extend .text-danger;
      }
    }
  }
}

.lh-1 {
  line-height: 1;
}

.btn-icon {
  line-height: 1;
  flex: 0 0 auto;
  padding: 0;
  border: none;
  @include size(38px);

  .material-icon {
    transform: scale(1.5);
  }

  html[dir="rtl"] & {
    transform: scaleX(-1) scale(1.5);
  }

  &.btn-sm {
    @include size(31px);
  }
}

.btn-icon-default,
.btn-icon-inverse {
  border-color: transparent !important;
  background-color: transparent;
}

.btn-icon-default {
  color: $md-dark-icons-default;
  @include hover {
    color: $md-dark-icons-active;
    background-color: $md-black-lighter;
  }
  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    color: $md-dark-icons-inactive;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  &.tippy-active,
  .show > &.dropdown-toggle {
    color: $md-dark-icons-active;
    background-color: $md-black-lighter;
  }
}

.star-btn {
  &.selected {
    color: $yellow;
  }
}

.checkbox-btn {
  &.selected {
    color: $primary !important;
  }
  &:not(.selected) {
    color: $md-black-lighter;
  }
}

.btn-icon-danger {
  color: theme-color("danger") !important;
}

.btn-icon-inverse {
  color: $md-light-icons-default;
  //text-shadow: $default-text-shadow;
  @include hover {
    color: $md-light-icons-active;
    background-color: $md-white-light;
  }
  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    color: $md-light-icons-inactive;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  &.tippy-active,
  .show > &.dropdown-toggle {
    color: $md-light-icons-active;
    background-color: $md-white-light;
  }
}

.btn-pseudo {
  height: 38px;
  //padding: 0;
  //
  //> * {
  //  line-height: $line-height-base;
  //}
}

.btn-icon-pseudo-bg {
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  color: $md-dark-icons-default;

  &::after {
    position: absolute;
    z-index: -1;
    @include size(90%);
    top: 5%;
    left: 5%;
    content: "";
    opacity: 0;
    border-radius: 5rem;
    background-color: $md-black-lighter;
  }

  @include hover {
    color: $md-dark-icons-active;
    &::after {
      opacity: 1;
    }
  }

  &:focus,
  &.focus {
    color: $md-dark-icons-active;

    &::after {
      opacity: 1;
    }
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    color: $md-dark-icons-inactive;

    &::after {
      opacity: 0;
    }
  }

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active,
  .show > &.dropdown-toggle {
    &::after {
      opacity: 1;
    }
  }
}

.trash-svg {
  fill: $md-dark-icons-default;
}

.trash-svg-top {
  transition: transform 250ms;
  transform: rotate(0deg);
  transform-origin: left bottom;
}

.btn-trash {
  padding-top: $btn-padding-y-lg * 2;
  padding-bottom: $btn-padding-y-lg * 2;

  &.drop-zone-valid {
    .trash-svg-top {
      transform: rotate(-45deg);
    }
  }
}

.customImage,
.btn-img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.btn-has-image-left {
  padding-left: ($spacer * 3);
  background-repeat: no-repeat;
  background-position: 11px 6px;
  background-size: 24px;
}

.btn-tab-item {
  font-size: $font-size-sm;
  margin-right: ($spacer / 2);
  margin-bottom: ($spacer / 2);
  border: none;
  background-color: rgba($black, 0.06);
  @include hover {
    //color: color-yiq($hover-background);
    background-color: rgba($black, 0.1);
  }
  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    //color: color-yiq($background);
    //background-color: $background;
    //border-color: $border;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: $white;
    @include mat-elevation(1);
    //color: color-yiq($active-background);
    //background-color: $active-background;
    //border-color: $active-border;
  }
}

.btn-white {
  @include button-variant($white, $white);
}

.btn-outline-secondary {
  color: $body-color;
  border: $default-border;
  background-color: transparent;
}

//
// Flat Buttons
//
.btn-flat-primary {
  @include button-flat-variant(theme-color("primary"), $default-hover-bg);
}

.btn-flat-secondary {
  @include button-flat-variant($gray-600, $default-hover-bg);

  .navbar-dark & {
    @include button-flat-variant($navbar-dark-color, rgba($white, 0.2));

    .material-icon {
      color: inherit;
    }
  }
}

.btn-flat-dark {
  @include button-flat-variant($body-color, $default-hover-bg);
}

.btn-flat-danger {
  @include button-flat-variant(theme-color("danger"), transparentize(theme-color("danger"), 0.85));
}

//
// Buttons
//
.btn-raised {
  @include mat-elevation-transition();
  @include mat-elevation(2);

  &:focus {
    @include mat-elevation(2);
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    box-shadow: none !important;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    @include mat-elevation(6);
  }
}

.list-inline-clickable-path {
  li {
    vertical-align: middle;
  }
}

.btn-directory-label,
.EditableLabel .cursor-pointer {
  font-weight: $font-weight-normal;
  background-color: transparent;

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
  }
}

.btn-crop-option {
  @include hover {
    background-color: $md-black-lighter;
  }

  &:not(:disabled):not(.disabled).active {
    color: $white;
    border-color: theme-color("primary");
    background-color: theme-color("primary");
  }

  & + .btn-crop-option {
    margin-left: $spacer;
  }
}

.crop-option {
  width: 50px;
}

.MBButton {
  @extend .btn-pseudo;

  &.disabled,
  &.noHover {
    &:hover {
      background-color: transparent;
    }
  }

  &.removePMI {
    &,
    &::before {
      opacity: 1;
      color: $form-feedback-invalid-color !important;
    }
  }

  &.showAllButton {
    &::before {
      content: "folder_open";
    }

    &.showDirectButton {
      &::before {
        content: "folder";
      }
    }
  }

  &.xmpfieldeditNoAction:before {
    content: "do_not_disturb";
  }

  &.xmpfieldeditAdd:before {
    content: "control_point";
  }

  &.xmpfieldeditClearField:before {
    content: "clear_field";
  }

  &.xmpfieldeditEquals:before {
    content: "compare_arrows";
  }

  &.xmpfieldeditAppend:before {
    content: "play_circle_outline";
    transform: rotate(180deg);
  }

  &.removeSelection:before {
    content: "view_comfy";
    opacity: 1;
    color: $form-feedback-invalid-color !important;
  }

  &.Add-To-Basket:before {
    content: "add_shopping_cart";
  }

  &.Show-in-view-widget:before {
    content: "open_in_new";
  }

  &.EMail:before {
    content: "local_post_office";
  }

  &.Create-PDF:before {
    content: "print";
  }

  &.Download-items:before {
    content: "file_download";
  }

  &.Save-Basket:before {
    content: "save";
  }

  &.New-Basket:before {
    content: "add_shopping_cart";
  }

  &.Clear-Active-Basket:before {
    content: "remove_shopping_cart";
  }

  &.x-tool-minimize:before {
    content: "remove";
  }

  &.xmpfieldeditDelete:before {
    content: "remove";
  }

  &.x-tool-maximize:before {
    content: "fullscreen";
  }

  &.externalShare:before {
    content: "open_in_browser";
  }

  &.toggleMetadata:before {
    content: "format_align_left";
  }
}

.MBHoverChangeButton {
  cursor: pointer;
  background-repeat: no-repeat;

  > .standard_font {
    padding-left: 21px;
  }

  &.wideImage > .standard_font {
    padding-top: 3px;
    padding-left: 26px;
  }
}

.toolbarButton {
  padding: 2px;
  cursor: pointer;
  background: no-repeat 50%;

  &:active {
    background-color: $color_iron_approx;
    box-shadow: inset 0 1px $black_10;
  }

  &:focus {
    border: 1px solid $pink;
    outline: 0;
    box-shadow: inset 0 1px $white_36, 0 0 0 2px $color_malibu_approx;
  }

  &:hover {
    background-color: $md-gray-100;
  }
}

.btn-nav-item {
  height: auto !important;
  padding: 1rem 0 !important;
  color: $md-white-dark !important;
  background-color: transparent;
  @include hover {
    color: $md-white-darker !important;
    background-color: $md-white-lighter !important;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: $white !important;
  }
}

.btn-star {
  &::before {
    transform: scale(1.5);
  }
}
